// Global Modal Styles
ion-modal {
  &::part(handle) {
    display: none;
  }
  .modal-title-row {
    padding: 12px 8px 8px 16px;
    color: var(--color-content-primary);
    @include l-alt-head;
  }
  .modal-button-row {
    @include l-body;
    padding: 16px;
    gap: 12px;
  }
  .modal-text,
  .modal-actions {
    @include m-body;
    padding: 16px;
  }
  .modal-text {
    &.estimated-cost-modal {
      padding: 0;
      margin-top: -20px;
    }
  }
  .close-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.help-modal,
.repeat-order-modal {
  --border-radius: var(--border-radius-large);
  --background: var(--color-background-alt);
}

.help-modal::part(backdrop) {
  background: rgba(0, 0, 0, 0.25);
}

// Profile and Pickup Instructions modal
.profile-modal,
.instructions-modal {
  h3 {
    color: var(--color-content-primary);
    @include l-alt-head;
    position: relative;
    left: 8px;
  }

  .delete-profile-modal {
    .delete-confirm-col {
      @include l-body;
    }
    poplin-button {
      poplin-icon {
        top: 0px;
      }
    }
  }
  poplin-button {
    width: 100%;
    margin: auto;
  }
  .ion-page {
    height: 100%;
  }
}
// Preferred LP Modal
.preferred-modal {
  .modal-button-row {
    ion-col {
      display: grid;
      gap: 12px;
    }
  }
}

.redeem-modal {
  --background: rgba(0, 0, 0, 0.25);

  p {
    margin-bottom: 15px !important  ;
  }
}

.download-app-modal {
  --background: rgba(0, 0, 0, 0.25);

  p {
    margin-bottom: 15px !important  ;
  }
}
