@import "typography.scss";
@import "variables.scss";

.uppercase {
  text-transform: uppercase;
}

.link-text {
  text-decoration: underline;
  color: var(--color-content-link);
}
