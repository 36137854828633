/* Ionic Overrides */
/* ================================================ */
ion-button {
  height: inherit;
}

html.plt-ios app-review-order {
  padding-top: calc(var(--ion-safe-area-right) + 40px);
  padding-bottom: calc(var(--ion-safe-area-left) + 40px);
}
