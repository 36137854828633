// wrapper for all content in-app following header + text + content pattern
// see: order flow pages, referrals, account details

.basic-layout-frame {
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;

  &.small {
    gap: 16px;
  }

  &.med {
    gap: 24px;
  }

  .intro-text {
    color: var(--color-content-alt);

    p {
      @include m-body;
      margin: 0;
      padding: 0;

      &:only-child {
        color: var(--color-content-primary);
      }
    }

    h2 {
      @include m-subhead;
      margin: 0 0 4px;
      padding: 0;

      &:only-child {
        margin-bottom: 0;
      }
    }
  }

  .order-pricing-frame {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .choice-card-frame {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .guided-checkbox {
    display: flex;
    gap: 12px;
    align-items: center;

    poplin-checkbox {
      flex: 1 1 auto;
    }
    poplin-button {
      flex: 0 1 auto;
    }
  }

  .opening-checkbox {
    .expanding-area {
      padding: 8px 0 0 44px;

      .checkbox-text-description {
        @include m-body;
        color: var(--color-content-alt);
      }
    }
  }

  .subpage-button {
    padding: 8px;
  }

  .subpage-link {
    display: block;
    width: 100%;
    padding: 13px 12px;
    @include m-body;
    text-align: center;
  }
}
