/* Global Auth Styles */
/* ================================================= */
.ion-page:has(.auth-page):not(.flex-start) .auth-page {
  flex: 1;
}
.md {
  .header {
    margin-top: 0;
  }
}

.auth-page {
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-bottom: 24px;

    h1 {
      width: 100%;
      @include l-alt-head;
      color: var(--color-content-primary);
      margin: 0;
      position: relative;
      text-align: center;
      poplin-button {
        position: absolute;
        transform: translate(calc(-100% - 4px), -4px);
        display: inline-block;
      }
    }
    p {
      @include m-body;
      color: var(--color-content-alt);
      margin: 0;
      text-align: center;

      strong {
        color: var(--color-content-primary);
      }
    }
  }
  header,
  section {
    padding: 0 36px;
  }

  ion-grid {
    min-height: 100%;
  }

  poplin-input {
    display: block;
    flex: 1;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    label {
      @include m-body;
      color: var(--color-content-primary);
    }
    #recaptcha-container {
      position: absolute;
    }
  }

  .inline-form-group {
    display: flex;
    gap: 12px;
  }

  .auth-faux-footer {
    padding: 24px 36px;
    position: sticky;
    top: 100%;

    p {
      @include s-body;
      color: var(--color-content-alt);
    }
  }
}
