// Google Maps Address Results styling
.pac-container {
  min-width: 329px !important;
  max-width: 440px !important;
  top: 19.15rem !important;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--color-border-alt);
  background: var(--color-background-primary);
  padding: var(--card-spacing-padding-top);
  display: grid;
  gap: 8px;
  /* Dark/Basic Drop Shadow */
  box-shadow: 0px 4px 24px 0px rgba(255, 255, 255, 0.08);
  .pac-item {
    height: 44px;
    border: none;
    .pac-matched,
    .pac-item-query::after {
      @include m-body;
      font-size: 0.875rem;
      font-weight: 700;
      content: "\a";
      white-space: pre;
    }
    :nth-child(3) {
      @include s-body;
      position: relative;
      left: 24px;
      bottom: 12px;
    }
    .pac-item-query {
      color: var(--color-content-alt);
    }
  }
}
.pac-logo::after {
  display: none;
}
// Small Screen
@media screen and (max-width: 375px) {
  .pac-container {
    top: 16.65rem !important;
    min-width: 311px !important;
    width: 311px !important;
  }
}
// Medium Screen
@media screen and (min-width: 376px) and (max-width: 413px) {
  .pac-container {
    width: 329px !important;
  }
}
// Large Screen
@media screen and (min-width: 414px) and (max-width: 439px) {
  .pac-container {
    width: 366px !important;
  }
}
// XL Screen
@media screen and (min-width: 440px) {
  .pac-container {
    top: 15.5rem !important;
    width: 440px !important;
  }
}
