@import "typography.scss";
@import "variables.scss";
@import "tokens.scss";

:host {
  margin-inline: 0 !important;
}

ion-grid {
  margin-inline: 0 !important;
}

ion-content {
  --padding-top: 36px;
  --padding-bottom: 36px;
  --padding-start: 24px;
  --padding-end: 24px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::webkit-scrollbar {
    display: none;
  }

  & > ion-grid {
    --ion-grid-padding: 0;
    & > ion-row,
    & > app-customer-pickup-location-form {
      max-width: 440px;
      margin: auto;
    }
  }

  // Intro Screens
  &.no-padding {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
  }

  // less padding
  &.small-padding {
    --padding-top: 24px;
    --padding-bottom: 24px;
    --padding-start: 24px;
    --padding-end: 24px;
  }

  // Faux-center EmptyState components
  &.empty-state-page {
    --padding-top: 156px;
  }

  // pages with dim gray backgrounds
  &.interior-page {
    --background: var(--color-background-dim);
  }

  // Use this class to add extra horizontal padding to flow pages
  &.flow-page {
    --padding-start: 32px;
    --padding-end: 32px;
  }
}
