ion-loading.poplin-loading {
  --background: var(--color-loader-background);
  --spinner-color: var(--color-loader-spinner);
  --backdrop-opacity: 0.25;
  --width: auto;
  --max-width: calc(100vw - 24px);

  .loading-wrapper {
    border-radius: var(--border-radius-default);
    box-shadow: var(--heavy-box-shadow);
    padding: 12px;
    gap: 18px;
  }

  .loading-spinner,
  .loading-spinner ion-spinner {
    height: 36px;
    width: 36px;
  }

  .loading-content {
    color: var(--color-content-primary);
    margin-inline-start: 0 !important;
    padding-right: 12px;
    @include m-body;
  }

  &.size-small {
    .loading-wrapper {
      border-radius: var(--border-radius-small);
      padding: 8px;
      gap: 12px;
    }

    .loading-spinner,
    .loading-spinner ion-spinner {
      height: 24px;
      width: 24px;
    }

    .loading-content {
      padding-right: 8px;
      @include s-body;
    }
  }

  &.size-large {
    .loading-wrapper {
      border-radius: var(--border-radius-large);
      padding: 18px;
      gap: 24px;
    }

    .loading-spinner,
    .loading-spinner ion-spinner {
      height: 48px;
      width: 48px;
    }

    .loading-content {
      padding-right: 10px;
      @include l-body;
    }
  }
}
