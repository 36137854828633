:root {
  --nav-footer-active-icon-background: var(--pink-core);
  --nav-footer-active-icon-background-hover: var(--pink-tint);
  --nav-footer-svg-color: var(--black);
  --nav-footer-svg-active-color: var(--white);

  // loader styles
  --color-loader-spinner: var(--pink-650);
  --color-loader-background: rgba(255, 255, 255, 0.8);

  // home
  --color-home-page-button-background: var(--white);
  --home-page-button-shadow: var(--heavy-box-shadow);
  --color-home-page-hightlight: var(--pink-tint);
}
body.dark {
  --nav-footer-active-icon-background: var(--white);
  --nav-footer-active-icon-background-hover: var(--gray-800);
  --nav-footer-svg-color: var(--white);
  --nav-footer-svg-active-color: var(--black);

  // loader styles
  --color-loader-spinner: var(--white);
  --color-loader-background: rgba(0, 0, 0, 0.8);

  // home
  --color-home-page-button-background: var(--gray-850);
  --home-page-button-shadow: none;
  --color-home-page-hightlight: var(--pink-core);
}
