/* Global HTML Element Styles */
/* ================================================= */

h1 {
  @include l-alt-head;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fakt-Normal", "Manrope-Normal", Helvetica, sans-serif;
}
body,
p {
  @include m-body;
  margin: 0;
  padding: 0;
}
