/* Poplin Design Package */
@import "../node_modules/@sudshare/custom-design-package/assets/theme/custom-global.scss";
@import "../node_modules/@sudshare/custom-design-package/assets/theme/colors.scss";
@import "../node_modules/@sudshare/custom-design-package/assets/theme/forms.scss";
@import "../node_modules/@sudshare/custom-design-package/assets/theme/tokens.scss";
@import "../node_modules/@sudshare/custom-design-package/assets/theme/typography.scss";
@import "../node_modules/@sudshare/custom-design-package/assets/theme/variables.scss";

/* Core element styles */
@import "./assets/styles/ionic-overrides.scss";
@import "./assets/styles/tokens.scss";
@import "./assets/styles/html-elements.scss";
@import "./assets/styles/utility-classes.scss";

/* Component styles */
@import "./assets/styles/loading.scss";
@import "./assets/styles/header.scss";
@import "./assets/styles/footer.scss";
@import "./assets/styles/modals.scss";
@import "./assets/styles/google-maps.scss";
@import "./assets/styles/stripe-web-modal.scss";
@import "./assets/styles/help-accordion.scss";
@import "./assets/styles/faq-list.scss";
@import "./assets/styles/smart-banner.scss";
/* Global Page styles */
@import "./assets/styles/grid.scss";
@import "./assets/styles/basic-frame.scss";
@import "./assets/styles/auth-pages.scss";
@import "./assets/styles/forms.scss";

/* Individual Page styles */
@import "./assets/styles/pickup-location-laundry-preferences-pages.scss";

ion-modal.custom-component-modal {
  --width: 352px;
  --min-width: 352px;
  --height: fit-content;
  --border-radius: 6px;
  --backdrop-opacity: 0.25;

  & > .ion-page {
    margin: 12px;
  }
}
