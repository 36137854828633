.help-accordion {
  .accordion-body.open {
    > p {
      @include m-body;
      color: var(--color-content-alt);

      h3 {
        @include m-subhead;
        color: var(--color-content-primary);
        margin: 0 0 8px;
      }
      h4 {
        @include s-alt-head;
        margin: 0 0 4px;
      }
      p + h3,
      p + h4,
      h4 + h3 {
        margin-top: 24px;
      }
      p {
        margin: 0 0 8px;
      }
      ul,
      ol {
        margin: 0 0 8px;
        li:not(:last-child) {
          margin: 0 0 4px;
        }
      }
    }
  }
}
